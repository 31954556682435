import React, {useState} from "react";
import { graphql, Link } from 'gatsby';
import Layout from "../components/layout";
import FluidImage from "../components/fluidImage";
import FixedImage from "../components/fixedImage";
import WPLink from "../components/wpLink";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import useModalState from '../helpers/modalState';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import * as styleSheet from "./smiles.module.scss"

const SmilesTemplate = (props) => {

  const [showAppointmentModal, setShowAppointmentModal] = useModalState();

  function showModal(){
		if( !showAppointmentModal.modalOpen ){
			setShowAppointmentModal({
                modalOpen: true, 
                zipcode: showAppointmentModal.zipcode,
                greyfinchId: showAppointmentModal.greyfinchId,
                showCallCenterModalOnClose: showAppointmentModal.showCallCenterModalOnClose,
                callCenterModalOpen: showAppointmentModal.callCenterModalOpen
            });
		}
	}

  function handleApptKeyDown(e) {
    if (e.keyCode === 13 || e.keyCode === 32) {
        setShowAppointmentModal({
            modalOpen: true, 
            zipcode: showAppointmentModal.zipcode,
            greyfinchId: showAppointmentModal.greyfinchId,
            showCallCenterModalOnClose: showAppointmentModal.showCallCenterModalOnClose,
            callCenterModalOpen: showAppointmentModal.callCenterModalOpen
        });
		}
  }


  const [currentLightboxImageIndex, setcurrentLightboxImageIndex] = useState()
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false)
  const [currentLightboxText, setCurrentLightboxText] = useState('')
  const [currentLightboxLocation, setCurrentLightboxLocation] = useState('')


  function openLightbox(lightboxIndex, titleText, locationText){
    setcurrentLightboxImageIndex(lightboxIndex)
    setLightboxIsOpen(true)
    setCurrentLightboxText(titleText, locationText)
    setCurrentLightboxLocation(locationText)
  }

  function handleLightboxKeyDown(e, lightboxIndex, titleText, locationText){
    if (e.keyCode === 13 || e.keyCode === 32) {
      openLightbox(lightboxIndex, titleText, locationText)
    }
  }

	const {
		data: {
			pageInfo,
			pageInfo: {
				title,
				ACFSmilesPage: {
					smilesPageInfo: {
						leftSide: {
							blurb,
							requestAppointmentLinkText,
						},
						rightSide: {
							introContent: {
								blurb: introBlurb,
								heading: introHeading,
								link: introLink
							},
							pageContent: content,
						}
					}
				}
			},
			options: {
				headerSection: {
					ACFHeaderOptions: {
						mainNavigationSettings: {
              mobileNavigationLogo
						} 
					}
				}

			},
			smiles: {
				nodes: smilePosts
			},
			rightArrowYellow,
			introBGMobile,
			introBGTablet,
			introBGDesktop,
			introBGXL,
		}
	} = props;


  let lightboxSmileImages = []
  smilePosts.forEach( (smile, index) => {
      if(smile.smiles.smileImage?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src) {
        lightboxSmileImages.push(smile.smiles.smileImage.localFile.childImageSharp.gatsbyImageData.images.fallback.src)
      } 
  })
  

	return (
		<Layout hideSmilesSection={true}>
			<Seo post={pageInfo} />
			<div className={styleSheet.top}>
				<div className={styleSheet.left}>
					<div className={styleSheet.lefttop}>
						{ mobileNavigationLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={mobileNavigationLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
						<h1 className={styleSheet.patientname} dangerouslySetInnerHTML={{__html: title}}></h1>
					</div>
					<div className={styleSheet.leftbottom}>
						{ blurb && <div className={styleSheet.patientheading} dangerouslySetInnerHTML={{__html: blurb}}></div> }
						{ requestAppointmentLinkText && <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.request} aria-expanded={showAppointmentModal.modalOpen ? true : false}>{requestAppointmentLinkText}</button> }
					</div>
				</div>
				<div className={styleSheet.right}>
					{ introBGMobile && <FluidImage image={introBGMobile} passedClass={styleSheet.bgmobile} loading={"eager"} /> }
					{ introBGTablet && <FluidImage image={introBGTablet} passedClass={styleSheet.bgtablet} loading={"eager"} /> }
					{ introBGDesktop && <FluidImage image={introBGDesktop} passedClass={styleSheet.bgdesktop} loading={"eager"} /> }
					{ introBGXL && <FluidImage image={introBGXL} passedClass={styleSheet.bgxl} loading={"eager"} /> }
					{ mobileNavigationLogo && <Link to="/" className={styleSheet.logolink}><FluidImage image={mobileNavigationLogo} passedClass={styleSheet.logo} loading={"eager"} /></Link>}
					<h1 className={styleSheet.patientname} dangerouslySetInnerHTML={{__html: title}}></h1>
					{ introHeading && <div className={styleSheet.heading} dangerouslySetInnerHTML={{__html: introHeading}} data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease-in"></div> }
					{ introBlurb && <div className={styleSheet.blurb} dangerouslySetInnerHTML={{__html: introBlurb}} data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease-in"></div> }
					{ introLink && <WPLink link={introLink} icon={rightArrowYellow} passedClass={styleSheet.link} passedIconClass={styleSheet.icon} data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-in" /> }
          { !introLink && <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.link} data-sal="slide-up" data-sal-delay="400" data-sal-easing="ease-in" aria-expanded={showAppointmentModal.modalOpen ? true : false}>{requestAppointmentLinkText ? requestAppointmentLinkText : 'Make an appointment'} <FixedImage image={rightArrowYellow} passedClass={styleSheet.icon} /></button>}
				</div>
			</div>
			<div className={styleSheet.bottom}>
				<div className={styleSheet.right}>
					<div className={styleSheet.patientcontent}>
						<div className={styleSheet.contentleft}>
							{ content && <div className="wpcontent" dangerouslySetInnerHTML={{__html: content}}></div> }

                { smilePosts && (
                  <ul className={styleSheet.smileslist}>
                    {smilePosts.map( (smile, index) => {
                      const smilePost = smile.smiles;
                      return (
                      <li key={smile.id} className={styleSheet.smileitem}>
                          <button className={styleSheet.lightboxtarget}  onKeyDown={(e) => handleLightboxKeyDown(e, index, "", "")} onClick={() => openLightbox(index, "", "")}>
                            {smilePost.smileImage && <FluidImage image={smilePost.smileImage} passedClass={styleSheet.smileimage} loading={"lazy"} />} 
                           
                          </button>
                      </li>)
                    })}
                    {lightboxIsOpen && (
                      <Lightbox 
                        mainSrc={lightboxSmileImages[currentLightboxImageIndex]} 
                        onCloseRequest={() => setLightboxIsOpen(false)} 
                        imageTitle={currentLightboxLocation}
                        imageCaption={currentLightboxText}
                        enableZoom={false}
                      />
                    )}
                </ul>)
                }
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`query ($id: String!) {
  pageInfo: wpPage(id: {eq: $id}) {
    seo {
      title
      metaDesc
      focuskw
      metaKeywords
      metaRobotsNoindex
      metaRobotsNofollow
      opengraphTitle
      opengraphDescription
      opengraphImage {
        altText
        sourceUrl
        srcSet
      }
      twitterTitle
      twitterDescription
      twitterImage {
        altText
        sourceUrl
        srcSet
      }
      canonical
      cornerstone
      schema {
        articleType
        pageType
        raw
      }
    }
    id
    title
    ACFSmilesPage {
      smilesPageInfo {
        leftSide {
          blurb
          requestAppointmentLinkText
        }
        rightSide {
          introContent {
            blurb
            heading
            link {
              target
              title
              url
            }
          }
          pageContent
        }
      }
    }
  }
  home: wpPage(isFrontPage: {eq: true}) {
    ACFHomePage {
      hero {
        callouts {
          callout1 {
            heading
            blurb
            makeAnAppointmentLinkText
          }
        }
      }
    }
  }
  options: wp {
    headerSection {
      ACFHeaderOptions {
        mainNavigationSettings {
          mobileNavigationLogo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 420, layout: CONSTRAINED, quality: 100)
              }
              publicURL
              extension
            }
          }
        }
      }
    }
  }
  rightArrowYellowOffsite: file(name: {eq: "arrow_offsite"}) {
    childImageSharp {
      gatsbyImageData(width: 10, layout: FIXED)
    }
  }
  rightArrowYellow: file(name: {eq: "arrow"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
  arrowBlue: file(name: {eq: "arrow_blue"}) {
    childImageSharp {
      gatsbyImageData(width: 9, layout: FIXED)
    }
  }
  introBGMobile: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 699, layout: CONSTRAINED)
    }
  }
  introBGTablet: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 550, layout: CONSTRAINED)
    }
  }
  introBGDesktop: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(width: 840, layout: CONSTRAINED)
    }
  }
  introBGXL: file(name: {eq: "patientinfobg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  calloutBG: file(name: {eq: "callout_overlay_girl"}) {
    childImageSharp {
      gatsbyImageData(width: 295, layout: CONSTRAINED)
    }
  }
  smiles: allWpSmile (
    filter: {status: {eq: "publish"}}
    sort: {fields: date, order: DESC}
  ) {
    nodes {
      id
      smiles {
        smileImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 699)
              }
            }
        }
      }
    }
}

}
`;

export default SmilesTemplate;